.OptionList {
  form {
    width: 100%;
  }

  .delete-grid {
    height: 100%;
  }

  .options-error {
    margin: 1rem 0;
  }
}
