.AuditQuery {
  .datePicker {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .dropdown {
    margin-bottom: 0.7rem;
  }
}
