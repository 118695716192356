.Dashboard {
  margin-top: 2rem;

  .category-name {
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .dash-container {
    width: 100%;
  }
}
