.CategoryForm {
  .title {
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  .radioButtons {
    float: left;
  }
  .has-shadow {
    border: 1px whitesmoke solid;
    border-radius: 0.5em;
    box-shadow: var(--box-shadow);
    margin: 2rem 0rem 2rem;
    padding: 1em 2em 2em;
    width: 35%;
  }
  .warning {
    color: rgb(233, 12, 12);
  }
  .textField {
    display: flex;
    width: 100%;
  }
  .actionButtons {
    display: flex;
    justify-content: space-between;
    width: 90%;

    .addButton {
      background-color: var(--primary-color);
      color: white;
      text-transform: capitalize;
      font-weight: bold;
      margin-top: 15px;
    }

    .deleteButton {
      background-color: rgb(211, 32, 32);
      color: white;
      text-transform: capitalize;
      font-weight: bold;
      margin-top: 15px;
    }
    .editButton {
      background-color: var(--tertiary-color);
      color: white;
      text-transform: capitalize;
      font-weight: bold;
      margin-top: 15px;
    }
  }
  .submitButton {
    background-color: var(--tertiary-color);
    color: white;
    text-transform: capitalize;
    font-weight: bold;
    margin-top: 15px;
  }
}
