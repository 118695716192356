.AuditInfo {
  flex-grow: 1;

  .headline {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: var(--secondary-color);
  }

  .deleteAudit {
    justify-content: flex-end;
    float: right;
  }

  .editAudit {
    background-color: var(--primary-color);
    font-family: roboto;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    text-transform: capitalize;
    font-size: 13px;
    margin-bottom: 20px;
    float: right;
    margin-right: 30px;
  }

  .show-link,
  .show-link:visited {
    color: inherit;
    text-decoration: none;
  }
}

.FormRow {
  .paper {
    padding: 2px;
    text-align: center;
    background-color: var(--tertiary-color);
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
  }

  .paperAnswer {
    padding: 2px;
    text-align: center;
    font-size: 14px;
    background-color: #f2f5f8b2;
  }
}
