.Section {
  display: 'flex';
  margin-top: 0px;

  .smallboxContent {
    background-color: #e8e8e9;
    padding: 10px;
  }

  .section-container {
    text-align: left;
    font-weight: bold;
    font-size: 20px;
  }
}
