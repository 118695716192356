.BlockIndex {
  text-align: center;

  .newBlock {
    background-color: var(--primary-color);
    font-family: roboto;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    text-transform: capitalize;
    font-size: 13px;
    margin: auto;
    margin-bottom: 30px;
    justify-content: flex-end;
  }

  .show-link,
  .show-link:visited {
    color: inherit;
    text-decoration: none;
  }
}
