.FormShow {
  flex-grow: 1;

  .headline {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: var(--secondary-color);
    margin-bottom: 50px;
  }
  .actionButtons {
    display: flex;
    justify-content: space-between;
    width: 30%;
  }
  .deleteForm {
    justify-content: flex-end;
  }

  .editForm {
    background-color: var(--primary-color);
    font-family: roboto;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    text-transform: capitalize;
    font-size: 13px;
  }
}

.FormShowRow {
  .paper {
    padding: 2px;
    text-align: center;
    background-color: var(--tertiary-color);
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
  }

  .paperAnswer {
    padding: 2px;
    text-align: center;
    font-size: 14px;
    background-color: #f2f5f8b2;
  }
}
