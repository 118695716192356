.TableWithPagination {
  width: 80%;
  margin: auto;

  .table {
    width: 100%;
    margin: auto;
  }

  .tableHead {
    background-color: var(--tertiary-color);
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    font-size: 16px;
  }

  .body {
    font-size: 14px;
    text-align: center;
  }

  .buttonView,
  .buttonEdit {
    background-color: var(--tertiary-color);
    color: white;
    text-transform: capitalize;
    font-weight: bold;
  }

  .buttonDelete {
    background-color: rgb(211, 32, 32);
    color: white;
    text-transform: capitalize;
    font-weight: bold;
  }

  .show-link,
  .show-link:visited {
    color: inherit;
    text-decoration: none;
  }

  .actionButtons {
    display: inline-flex;
    gap: 0.5rem;
  }
}
