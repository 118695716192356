.TableGrid {
  display: flex;
  height: 600px;

  .MuiDataGrid-columnsContainer,
  .MuiDataGrid-columnHeader {
    background-color: var(--tertiary-color);
    color: white;

    .MuiIconButton-label {
      color: white;
    }
  }

  .button-view,
  .button-edit,
  .button-delete {
    color: white;
    text-transform: capitalize;
    font-weight: bold;
  }

  .button-view {
    background-color: var(--primary-color);
  }

  .button-edit {
    background-color: var(--tertiary-color);
  }

  .button-delete {
    background-color: var(--red);
  }
}
