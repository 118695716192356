.UserShow {
  border: 1px var(--light-gray) solid;
  border-radius: 0.5rem;
  box-shadow: var(--box-shadow);
  margin: 3rem auto;
  max-width: fit-content;

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  title {
    color: var(--secondary-color);
  }

  strong {
    color: var(--primary-color);
  }

  .box {
    padding: 2rem;
  }
}
