.Selector {
  margin: auto;

  .button {
    margin: auto;
  }
}
.SelectorList {
  margin: auto;

  .cardHeader {
    padding: 1px;
  }

  .list {
    width: 500px;
    height: 470px;
    background-color: #ffffff;
    overflow: auto;
  }
}
