.AnsweredQuestion {
  display: flow-root;

  .boxContent {
    display: flex;
    font-size: 15px;
    margin-top: 20px;
    text-align: left;
  }
  .optionsDisplay {
    display: flex;
    font-size: 16px;
    width: 100%;
  }

  .radiobuttonLabel {
    font-size: 12px;
  }

  .notdisplayed {
    display: none;
  }

  .textanswer {
    width: 400px;
  }

  .save {
    font-size: 15px;
    text-transform: capitalize;
    font-weight: bold;
    display: flex;
    height: 30px;
    float: right;
    justify-content: center;
    margin-top: 5px;
    margin-left: 10px;
    background-color: var(--primary-color);
    color: #ffffff;
  }

  .displayed {
    font-size: 15px;
    text-transform: capitalize;
    font-weight: bold;
    display: flex;
    height: 30px;
    margin: auto;
    float: right;
    justify-content: center;
    background-color: var(--tertiary-color);
    color: #ffffff;
  }

  .answerMultiple {
    margin-top: 10px;
  }
}
