.Login {
  .MuiCircularProgress-circle {
    stroke: white;
  }

  .submit-btn {
    margin-top: 1rem;
  }

  .central-row {
    padding: 1rem;
  }

  .error-text {
    margin-top: -0.5rem;
    height: 2rem;
    font-size: 1rem;
  }
}
