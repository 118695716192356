.PopUp {
  position: relative;

  .dialog-title {
    text-align: center;
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .close-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    margin-left: 1.5rem;
  }
}
