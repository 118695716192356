.Footer {
  font-size: 2rem;
  background-color: var(--light-gray);
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

p {
  font-size: 1rem;
}
