* {
  box-sizing: border-box;
}

.navbar :focus {
  outline: none;
}

.navbar {
  top: 0;
  z-index: 1;
  width: 100%;
  position: fixed;
  background-color: var(--secondary-color);
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  text-align: center;
  list-style-type: none;
  letter-spacing: 0.03rem;
}

.navbar a {
  color: white;
  display: block;
  float: left;
  text-decoration: none;
  transition: 200ms background-color ease-out;

  &:not(.logo-link) {
    padding: 0.8rem 1rem;
  }
}

.navbar a:hover:not(.active):not(.logo-link) {
  background-color: var(--primary-color);
}

.navbar .active {
  background-color: var(--tertiary-color);
}

.navbar .dropdown {
  float: left;
  overflow: hidden;
}

.navbar .dropdown:hover {
  cursor: default;
}

.dropbtn {
  color: white;
  display: block;
  padding: 0.8rem 1rem;
  text-decoration: none;
  transition: 200ms background-color ease-out;
}

.dropdown-content {
  z-index: 1;
  display: none;
  min-width: 9.125rem;
  position: absolute;
  background-color: var(--tertiary-color);
  box-shadow: var(--box-shadow);
  max-height: calc(100vh - 2.5rem);
  overflow-y: scroll;
}

.dropdown-content a {
  float: none;
  display: block;
  text-align: left;
}

.dropdown:hover .dropdown-content {
  display: flex;
  flex-direction: column;
  position: fixed;
}

.navbar .navbar-btn {
  display: none;
}

.navbar #navbar-check {
  display: none;
}

.navbar .navbar-logo {
  padding: 0;
  margin-top: 0.1rem;
  margin-left: 0.1rem;
  border-radius: 50%;
  background-color: #505e77;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 1.8rem;
  }

  &:hover {
    background-color: #262a35;
  }
}

@media (max-width: 768px) {
  .navbar {
    height: 2.5rem;
    position: auto;
  }

  .navbar .navbar-btn {
    float: left;
    display: block;
    position: absolute;
    z-index: 1;
  }

  .navbar .navbar-btn label span {
    display: block;
    width: 1.5rem;
    height: 0.6rem;
    border-top: 0.1rem solid white;
    margin: 0;
  }

  .navbar .navbar-btn label {
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.5rem;
  }

  .navbar .navbar-logo {
    display: none;
  }

  .navbar .navbar-links {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--secondary-color);
    height: 0px;
    transition: height 400ms ease-out;
    overflow-y: hidden;
  }

  .dropdown-content {
    padding-bottom: 5rem;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
  }

  .navbar #navbar-check:not(:checked) ~ .navbar-links {
    height: 0px;
  }

  .navbar #navbar-check:checked ~ .navbar-links {
    height: calc(6 * 2.75rem);
    overflow-y: hidden;
    position: fixed;
    top: 2.5rem;
  }

  .navbar :is(.dropdown, .dropbtn) {
    background-color: var(--secondary-color);
    float: none;
    display: block;
  }

  .navbar :is(.dropdown, .dropbtn):is(:hover, :focus) {
    background-color: var(--primary-color);
  }
}

.navbar-spacer {
  height: 4rem;
}

.CloseSessionButton {
  color: white;
  cursor: pointer;
  border: none;
  float: right;
  font-size: 1rem;
  padding: 0.8rem 1rem 0.8rem;
  transition: 200ms background-color ease-out;
  background-color: var(--tertiary-color);
  min-width: 8rem;
  &:hover {
    background-color: var(--primary-color);
  }

  .MuiCircularProgress-circle {
    stroke: white;
  }
}
