.CategoryShow {
  ul {
    line-height: 1.75rem;
    padding-left: 1rem;

    li::marker {
      color: var(--tertiary-color);
    }
  }

  strong {
    color: var(--tertiary-color);
  }
}
