.TecNewForm {
  text-align: center;
  margin-top: 2rem;

  .submit-button {
    margin: 8px;
  }

  .submit-icon {
    margin-right: 8px;
    margin-left: 8px;
    font-size: 20;
  }

  .rut-field,
  .name-field,
  .last-names-field,
  .company-field {
    margin-left: 8px;
    margin-right: 8px;
    width: 400px;
  }

  &.container {
    padding-top: 10%;
    width: 500px;
    margin: auto;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
