.AuditPhotos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: hidden;
  margin-top: 2rem;
  margin: 2rem 3rem;

  .MuiGridList-root {
    flex-wrap: nowrap;
    height: 400px;
  }

  .list-tile {
    cursor: pointer;
    // TODO: Find a way without using !important
    height: 100% !important;
  }

  .ImageList {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
}
