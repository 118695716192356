.AnswersAccordeon {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 80px;
  display: contents;

  .headingAccordion {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  .accordion {
    background-color: #e8e8e957;
    margin: auto;
    width: 90%;
    align-items: center;
    justify-content: center;
  }

  .details {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}
