.ReportShow {
  .Form {
    position: sticky;
    top: 3.5rem;
    display: fixed;
  }

  .AuditAnswers {
    width: 100%;
    padding: 0;
    margin-top: 1rem;
  }

  ul {
    line-height: 1.75rem;
    padding-left: 1rem;

    li::marker {
      color: var(--tertiary-color);
    }
  }

  strong {
    color: var(--tertiary-color);
  }
}
