.AuditEditForm {
  .form-title {
    margin: 1.5rem 1em;
  }

  .datetimeField {
    margin-top: 40px;
    margin-left: 8px;
    margin-right: 8px;
    width: 400px;
  }

  .datelabelField {
    font-size: 11px;
  }

  .submitButton {
    background-color: var(--primary-color);
    font-family: roboto;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    text-transform: capitalize;
    font-size: 13px;

    &:hover {
      background-color: var(--tertiary-color);
    }
  }
}
