.FormNew {
  text-align: center;

  .submitButton {
    background-color: var(--tertiary-color);
    font-family: roboto;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    text-transform: capitalize;
    font-size: 13px;
  }

  .formField {
    width: 400px;
  }

  .labelField {
    font-size: 16px;
  }
}
