.SearchBar {
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  .input {
    width: 20rem;
    background: #f2f1f9;
    border: none;
    padding: 0.5rem;
  }
}
