.AuditShow {
  .buttonStyle {
    display: flex;
    justify-content: center;
    font-size: 15px;
    font-weight: bold;
    background-color: var(--primary-color);
    text-transform: capitalize;
    margin: auto;
    margin-bottom: 30px;
  }
}
