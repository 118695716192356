.TecShow {
  .has-shadow {
    border: 1px whitesmoke solid;
    border-radius: 0.5em;
    box-shadow: var(--box-shadow);
    margin: 2rem 0rem 2rem;
    padding: 1em 2em 2em;
    width: 35%;
  }

  .headline {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: var(--secondary-color);
    margin-bottom: 20px;
  }
  .actionButtons {
    display: flex;
    justify-content: space-between;
    width: 20%;
  }

  .rut-field,
  .name-field,
  .telephone-field,
  .email-field,
  .category-field,
  .last-names-field,
  .enterprise-field {
    width: 400px;
  }

  .deleteTech {
    justify-content: flex-end;
  }
  .editTech {
    background-color: var(--primary-color);
    font-family: roboto;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    text-transform: capitalize;
    font-size: 13px;
  }

  .container {
    padding-top: 10%;
    width: 500px;
    margin: auto;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
