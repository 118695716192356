html,
body {
  margin: 0;
  height: 100%;
  font-family: Roboto;
}

#root {
  display: flex;
  min-height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Disable link underline and color change
a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
