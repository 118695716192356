.NotificationPanel {
  .title {
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  .buttonLink {
    background-color: var(--primary-color);
    color: white;
    text-transform: capitalize;
    font-weight: bold;
    margin-right: 0.5rem;
  }
  .actionContainer,
  .textBox {
    height: 100%;
  }
  .buttonDelete {
    color: var(--red);
  }
}
