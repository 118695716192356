.DashboardPanel {
  margin-bottom: 0.5rem;

  .dash-paper {
    padding: 1rem;
  }

  .dash-title {
    text-align: center;
    font-size: 1.4rem;
    margin: 1rem 0;
  }

  .progress-column {
    display: flex;
    justify-content: center;

    .progress-container {
      width: 100%;
      height: 100%;
    }

    .percentage {
      font-size: 3rem;
    }

    .percentage-text {
      margin-top: -0.4rem;
      font-size: 1.2rem;
    }
  }

  .stat-container {
    text-align: center;

    .stat-title {
      font-size: 1.2rem;
    }

    .stat-value {
      font-weight: bold;
      font-size: 1.7rem;
    }
  }
}
