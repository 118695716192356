.centerBox {
  gap: 2rem;
  height: 100%;
  padding: 4px;
}

.centerBox > .bigButton {
  background-color: var(--tertiary-color);
  color: white;
  font-size: 2rem;
  padding: 1rem;
  width: 20ch;
  text-transform: capitalize;

  &:hover {
    background-color: var(--primary-color);
  }
}
