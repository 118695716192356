.StartPanel {
  width: 100%;
  height: 100%;

  .title-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      padding-top: 0;
      font-size: 4rem;
    }

    .subtitle {
      margin-top: -0.2rem;
      font-size: 2rem;
    }
  }

  img {
    width: 250px;
  }
}

.Welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
