:root {
  --osam-blue: #29a1f3;
  --osam-purple: #5265bf;
  --osam-black: #353d4b;
  --red: #f43;
  --light-gray: rgb(224, 224, 224);
  --primary-color: var(--osam-blue);
  --secondary-color: var(--osam-black);
  --tertiary-color: var(--osam-purple);
  --box-shadow: 0.25rem 0.35rem 0.5rem 0rem rgba(0, 0, 0, 0.2);
}

.title {
  color: var(--secondary-color);
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  margin: 1.5rem;
}

.new-entity-btn {
  text-transform: capitalize;
  color: white;
  margin-bottom: 2rem;
  font-weight: bold;
  background-color: var(--primary-color);

  &:hover {
    background-color: var(--tertiary-color);
  }
}

button {
  font-family: inherit;
}

// Fix for forms moving when there is an error message
.MuiFormControl-root {
  padding-bottom: 0.7rem;

  .MuiFormHelperText-root {
    position: absolute;
    bottom: -0.5rem;
  }
}

// Button for sending forms
.submitButton,
.submit-button,
.button-submit {
  background-color: var(--primary-color);
  color: white;
  margin-top: 1rem;
  text-transform: capitalize;

  &:hover {
    background-color: var(--tertiary-color);
  }
}
