.Form {
  width: 100%;

  &.has-shadow {
    border: 1px whitesmoke solid;
    border-radius: 0.5em;
    box-shadow: var(--box-shadow);
    margin: 1rem 0rem 0rem;
    padding: 1em 2em 2em;
  }

  .form-title {
    color: var(--secondary-color);
    margin-left: 1em;
    margin-right: 1em;
    text-align: center;
  }

  .block {
    display: block;
  }
}
