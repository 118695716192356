.BlockDelete {
  .button {
    background-color: var(--red);
    color: white;
    font-weight: bold;
    text-transform: capitalize;
  }
}

.PopUp {
  .block-delete-btn {
    min-width: 7rem;
  }
}
